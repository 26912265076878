import dayjs from 'dayjs';
import { RestaurantDto } from '../core/api/generated/abuduba-api';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  restaurantAwards,
  restaurantCuisines,
  restaurantEstablishmentTypes,
  restaurantHighlights,
  restaurantPriceLevels,
} from './restaurants.types';

dayjs.extend(utc);
dayjs.extend(timezone);

export function getAwardName(award: RestaurantDto['awards'][number]): string {
  return restaurantAwards[award]?.name || '';
}

export function getPriceLevelName(
  priceLevel: RestaurantDto['priceLevel'],
): string {
  return restaurantPriceLevels[priceLevel]?.name || '';
}

export function getCuisineName(
  cuisine: RestaurantDto['cuisines'][number],
): string {
  return restaurantCuisines[cuisine]?.name || '';
}

export function getEstablishmentTypeName(
  type: RestaurantDto['establishmentTypes'][number],
): string {
  return restaurantEstablishmentTypes[type]?.name || '';
}

export function getHighlightName(
  highlight: RestaurantDto['highlights'][number],
): string {
  return restaurantHighlights[highlight]?.name || '';
}
