<app-not-found *ngIf="route === null"></app-not-found>

<div id="route_card" class="content-wrap" *ngIf="route !== null">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="route-title-block">
    <div class="route-title">
      <app-loader
        *ngIf="!route"
        [animation]="false"
        width="300px"
        height="30px"></app-loader>

      <h1 class="value">
        {{ route?.name }}
      </h1>
    </div>

    <div class="route-subtitle">
      <div class="route-type" *ngIf="route">
        <ng-icon [name]="getTypeIcon(route.type!)"></ng-icon>
        <div class="name">{{ getTypeName(route.type!) }}</div>
      </div>

      <div class="actions" *ngIf="route">
        <app-favourite-popup
          [textMode]="true"
          [itemId]="route.id"
          [saved]="isSavedInFavourites"
          [itemType]="
            CreateFavouriteItemDtoItemTypeEnum.Route
          "></app-favourite-popup>

        <app-share></app-share>

        <app-suggestion
          title="Leave suggestion for {{ route.name }}"
          entityId="{{ route.id }}"
          [entityType]="
            CreateSuggestionDtoEntityTypeEnum.Route
          "></app-suggestion>
      </div>
    </div>
  </div>

  <div class="route-media-block">
    <div class="loading-block" *ngIf="!route">
      <div class="left">
        <app-loader [animation]="false" width="100%" height="100%"></app-loader>
      </div>

      <div class="right">
        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>

        <div class="line">
          <app-loader
            [animation]="false"
            width="100%"
            height="100%"></app-loader>
        </div>
      </div>
    </div>

    <app-gallery *ngIf="media && route" [files]="media"></app-gallery>
  </div>

  <div class="route-content">
    <div class="main-info-block">
      <div class="description-block" *ngIf="route">
        <div
          class="description html-content"
          [innerHTML]="route.description"></div>
      </div>

      <div class="widgets">
        <div class="widget shadow-block general-info" *ngIf="route">
          <div class="general-info-item duration-item">
            <ng-icon name="matHourglassTopOutline"></ng-icon>
            <div class="item-value">
              Duration {{ formatMinutes(route.duration) }}
            </div>
          </div>

          <div class="general-info-item distance-item">
            <ng-icon name="matRouteOutline"></ng-icon>
            <div class="item-value">
              Distance {{ formatDistance(route.distance) }}
            </div>
          </div>

          <div class="general-info-item elevation-item">
            <ng-icon name="bootstrapGraphUp"></ng-icon>
            <div class="item-value">
              Elevation gain {{ route.elevationTotalGain }}m
            </div>
          </div>

          <div class="general-info-item difficulty">
            <ng-icon name="bootstrapHeartPulseFill"></ng-icon>
            <div class="item-value">
              Difficulty: {{ route.difficultyLevel }}
            </div>
          </div>

          <div class="general-info-item loop" *ngIf="isHiking">
            <ng-icon name="bootstrapRepeat"></ng-icon>
            <div class="item-value">
              Trail type: {{ route.isLoopTrail ? 'Loop' : 'One way' }}
            </div>
          </div>

          <div
            class="general-info-item places-item"
            *ngIf="places && places.length > 0">
            <ng-icon name="matLocationOnOutline"></ng-icon>
            <div class="item-value">
              Includes {{ places.length }} places:
              <span *ngFor="let p of getPlacesMeta()">{{ p.name }}</span>
            </div>
          </div>
        </div>

        <div
          class="links-widgets-block"
          *ngIf="route && (links.length > 0 || buttons.length > 0)">
          <div
            class="widget shadow-block button-widget"
            *ngFor="let b of buttons">
            <a class="button" [href]="b.url" target="_blank">{{ b.title }}</a>
          </div>

          <div
            class="widget shadow-block links"
            *ngIf="route && links.length > 0">
            <div class="widget-title">Useful links</div>

            <div class="link-item" *ngFor="let l of links">
              <div class="title">{{ l.title }}</div>
              <a class="url" [href]="l.url" target="_blank">{{ l.url }}</a>
            </div>
          </div>
        </div>

        <div class="widget" *ngIf="!route">
          <app-loader
            [animation]="false"
            width="100%"
            height="250px"></app-loader>
        </div>
      </div>
    </div>

    <div class="steps-block" *ngIf="route && placesMap && !isHiking">
      <div class="steps-list">
        <div class="step" *ngFor="let s of route.steps; let i = index">
          <div class="counter">{{ i + 1 }}</div>

          <div
            class="step-description html-content"
            [innerHTML]="s.description"></div>

          <div class="step-meta" *ngIf="s.duration">
            <div class="duration" *ngIf="s.duration">
              <div class="icon">
                <ng-icon name="matScheduleOutline"></ng-icon> Recommended
                duration:
              </div>
              {{ formatMinutes(s.duration) }}
            </div>
          </div>

          <div class="step-places">
            <app-place-near-by
              *ngFor="let place of getStepPlaces(s)"
              [place]="place"></app-place-near-by>
          </div>
        </div>
      </div>

      <div class="map">
        <app-places-map
          [center]="{
            lng: route.location.x,
            lat: route.location.y
          }"></app-places-map>
      </div>
    </div>

    <div class="hiking-map" *ngIf="route && isHiking">
      <div class="tabs" *ngIf="route.wikilocEmbedMap">
        <div
          class="tab"
          (click)="setTab('mapbox')"
          [class.active]="currentTab === 'mapbox'">
          MapBox
        </div>
        <div
          class="tab"
          (click)="setTab('wikiloc')"
          [class.active]="currentTab === 'wikiloc'">
          Wikiloc
        </div>
      </div>

      <div
        class="wikiloc"
        id="wikiloc"
        *ngIf="currentTab === 'wikiloc' && wikilocHtml">
        <div class="content" [innerHTML]="wikilocHtml"></div>
        <div class="loading-content" *ngIf="wikilocLoading">
          <app-loader></app-loader>
        </div>
      </div>

      <div class="mapbox" *ngIf="currentTab === 'mapbox'">
        <app-places-map
          [center]="{
            lng: route.location.x,
            lat: route.location.y
          }"
          [style]="'outdoors-v12'"
          [zoom]="isHiking ? 14 : 11"
          [googleMapLink]="googleMapUrl"></app-places-map>
      </div>
    </div>

    <div
      class="nearest-routes"
      *ngIf="nearestRoutes && nearestRoutes.length > 0">
      <app-entities-preview-list
        blockTitle="Nearby Finds"
        [nowrap]="true"
        [routes]="nearestRoutes"></app-entities-preview-list>
    </div>
  </div>
</div>
