import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { environment } from '../../../environments/environment';
import { ApiHelper } from '../api/api.helper';

export type MapCoordinates = { lng: number; lat: number };

@Component({
  selector: 'app-mapbox',
  templateUrl: './mapbox.component.html',
  styleUrls: ['./mapbox.component.scss'],
})
export class MapboxComponent implements AfterViewInit, OnDestroy {
  @ViewChild('map', { static: true }) mapboxElement: ElementRef;

  @Input()
  public width?: number | string = '100%';

  @Input()
  public height?: number | string = '100%';

  @Input()
  public zoom?: number = 10;

  @Input()
  public geocoderEnabled: boolean = true;

  @Input()
  public style: string = 'dark-v11';

  @Input()
  public coordinates: MapCoordinates | null = null;

  @Input()
  public addStartPoint = true;

  @Output()
  public coordinatesChange = new EventEmitter<mapboxgl.LngLatLike>();

  @Output()
  public located = new EventEmitter<mapboxgl.LngLatLike>();

  public map?: mapboxgl.Map;
  private geocoder: MapboxGeocoder;
  private marker: mapboxgl.Marker;
  private geolocateControl: mapboxgl.GeolocateControl;

  private emitter = new EventEmitter();

  constructor(
    private zone: NgZone,
    private readonly apiHelper: ApiHelper,
  ) {}

  public resize() {
    this.map?.resize();
  }

  public onMapLoad(cb: () => void) {
    if (this.map) {
      if (this.map.loaded()) {
        cb();
      } else {
        this.map.on('load', cb);
      }
    } else {
      this.emitter.subscribe(() => this.onMapLoad(cb));
    }
  }

  public get currentCoordinates(): MapCoordinates | null {
    const data = this.marker?.getLngLat();

    return data || null;
  }

  public locateUser() {
    this.geolocateControl?.trigger();
  }

  ngAfterViewInit() {
    if (!this.apiHelper.isBrowser) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      mapboxgl.accessToken = environment.mapboxToken;

      this.map = new mapboxgl.Map({
        container: this.mapboxElement.nativeElement,
        style: `mapbox://styles/mapbox/${this.style}`,
        zoom: this.zoom,
        center: this.coordinates || [55.2708, 25.2048],
      });

      this.marker = new mapboxgl.Marker({
        draggable: true,
      });

      this.geolocateControl = new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        fitBoundsOptions: {
          zoom: this.zoom,
          maxZoom: this.zoom,
        },
        trackUserLocation: false,
        showUserLocation: true,
      });

      this.geolocateControl.on('geolocate', (e) => {
        this.located.emit({ lat: e.coords.latitude, lng: e.coords.longitude });
      });

      this.map.addControl(this.geolocateControl);

      if (this.geocoderEnabled) {
        this.geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          // @ts-expect-error different version
          mapboxgl,
          marker: false,
        });
        // @ts-expect-error different version
        this.map.addControl(this.geocoder);
        this.map.addControl(new mapboxgl.NavigationControl());

        this.geocoder.on('result', (e) => {
          const coords = e.result.geometry.coordinates;
          this.coordinatesChange.emit({ lat: coords[1], lng: coords[0] });
        });

        this.geocoder.on('result', (e) => {
          const coords = e.result.geometry.coordinates;

          this.updateMarker({ lat: coords[1], lng: coords[0] });
        });
      }

      if (this.coordinates && this.addStartPoint) {
        this.updateMarker(this.coordinates);
      }

      this.map.addControl(new mapboxgl.FullscreenControl(), 'top-right');

      this.emitter.emit();
    });
  }

  updateMarker(coordinates: MapCoordinates) {
    if (this.map) {
      this.marker.setLngLat(coordinates).addTo(this.map);
    }
  }

  ngOnDestroy() {
    this.map?.remove();
  }

  moveTo(coordinates: MapCoordinates) {
    this.map?.flyTo({ center: coordinates });
  }
}
