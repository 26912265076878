import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  Input,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import ImageViewer from 'awesome-image-viewer';
import { SwiperContainer } from 'swiper/element/bundle';
import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { ApiHelper } from '../api/api.helper';
import 'swiper/css';

export interface IGalleryFile {
  url: string;
  mediumUrl: string;
  thumbUrl: string;
}

@Component({
  selector: 'app-gallery',
  templateUrl: 'gallery.component.html',
  styleUrl: 'gallery.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [NgOptimizedImage, NgForOf, NgIf],
})
export class GalleryComponent implements AfterViewInit {
  @Input()
  public files: IGalleryFile[];

  @Input()
  public preview?: boolean = false;

  @ViewChild('swiper')
  private swiperRef?: ElementRef<SwiperContainer>;

  public isInitialized = false;

  constructor(public apiHelper: ApiHelper) {}

  ngAfterViewInit() {
    this.isInitialized = true;

    const sw = this.swiperRef?.nativeElement;

    if (sw) {
      Object.assign(sw, {
        injectStyles: [
          `
          .swiper-pagination-bullet-active {
            background: #4546e0;
          }

          .swiper-button-next, .swiper-button-prev {
            background: white;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            color: black;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease, visibility 0.3s ease;
          }

          .swiper-button-disabled {
            display: none;
          }

          .swiper:hover .swiper-button-next,
          .swiper:hover .swiper-button-prev {
            opacity: 1;
            visibility: visible;
          }

          .swiper-button-next svg {
            height: 16px;
            left: 1px;
            position: relative;
          }

          .swiper-button-prev svg {
            height: 16px;
            left: -1px;
            position: relative;
          }`,
        ],
      });

      sw.initialize();

      const buttons = sw.shadowRoot?.querySelectorAll(
        '.swiper-button-next, .swiper-button-prev, .gallery-swiper',
      );

      buttons?.forEach((button) => {
        button.addEventListener('click', (event: Event) => {
          event.stopPropagation();
        });
      });
    }
  }

  openImage(index?: number): void {
    const instance = new ImageViewer({
      images: this.files.map((f) => ({
        mainUrl: f.url,
        thumbnailUrl: f.thumbUrl,
      })),
      // currentSelected: index,
      isZoomable: true,
      stretchImages: true,
    });

    // @ts-expect-error protected
    const dom = instance.view;
    dom.querySelector('.touchSurface')?.remove();
    const imagesWrapper = dom.querySelector('.imagesWrapper');
    if (imagesWrapper) {
      imagesWrapper.addEventListener(
        'click',
        (event) => {
          const target = event.target as Element;

          if (target && !target.closest('.imageContainer .image')) {
            instance.hide();
          }
        },
        { once: true },
      );

      const imageContainers = imagesWrapper.children;

      // "Disable" lazyloading
      for (let i = 0; i < imageContainers.length; i++) {
        const imageContainer: any = imageContainers.item(i);
        const url = imageContainer.dataset.url;
        const image = imageContainer!.getElementsByClassName('image')[0];
        (image as any).src = url;
      }

      //Scroll to clicked image (selected by index)
      setTimeout(() => {
        const imageContainer: any = imageContainers.item(index || 0);
        const imageCenterPosition =
          imageContainer.offsetLeft -
          (imagesWrapper.getBoundingClientRect().width -
            imageContainer.getBoundingClientRect().width) /
            2;
        imagesWrapper.scrollTo({
          left: imageCenterPosition,
          behavior: 'instant',
        });
      }, 100);
    }
  }

  get mainImage(): IGalleryFile {
    return this.files[0];
  }

  get sideImages(): IGalleryFile[] {
    return this.files.slice(1, 5);
  }

  public imageTrackBy(index: number) {
    return index;
  }
}
