import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FavouriteApiService,
  FavouriteListDto,
} from '../../core/api/generated/abuduba-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';

dayjs.extend(utc);

@Component({
  selector: 'app-favourites-page',
  styleUrls: ['favourites-page.component.scss'],
  templateUrl: 'favourites-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavouritesPageComponent implements OnInit {
  public lists?: FavouriteListDto[];
  public isLoading = false;

  constructor(
    private readonly favouriteApiService: FavouriteApiService,
    private route: ActivatedRoute,
    private titleService: Title,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.generatePageMeta();
  }

  private generatePageMeta() {
    const title = 'Abuduba - Favourites';

    this.titleService.setTitle(title);
  }

  ngOnInit() {
    this.updateData();
  }

  updateData() {
    this.isLoading = true;
    this.changeDetectorRef.detectChanges();

    this.favouriteApiService.getLists().subscribe((data) => {
      this.lists = data;
      this.isLoading = false;

      this.changeDetectorRef.detectChanges();
    });
  }

  getBreadcrumbs(): IBreadcrumb[] {
    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Favourites',
        url: '/favourite',
      },
    ];
  }
}
