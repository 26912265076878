import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActivitiesApiService,
  ActivityPreviewDto,
  FavouriteItemDto,
  NearestPlaceDto,
  PlaceDailyOpenHoursDto,
  PlaceDto,
  PlaceFullWeatherDto,
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
  RoutePreviewDto,
  RoutesApiService,
} from '../../core/api/generated/abuduba-api';
import { ActivatedRoute } from '@angular/router';
import { IGalleryFile } from '../../core/gallery/gallery.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { compact, sortBy, upperFirst } from 'lodash';
import {
  formatClosedType,
  formatDistance,
  formatMinutes,
  formatWorkingTime,
  getActivityIcon,
  getActivityName,
  getCurrentWorkingTime,
  getMainPictureUrl,
  isOpenNow,
} from '../places.utils';
import { AiChatComponent } from '../../core/ai-chat/ai-chat.component';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { PlacesMapComponent } from '../../core/places-map/places-map.component';
import { ApiHelper } from '../../core/api/api.helper';
import { CurrencyManager } from '../../core/currency-manager';
import { Meta, Title } from '@angular/platform-browser';
import {
  CreateFavouriteItemDtoItemTypeEnum,
  CreateSuggestionDtoEntityTypeEnum,
} from '../../core/api/generated/abuduba-api/shared-enums';
import { catchError, firstValueFrom, forkJoin, switchMap, tap } from 'rxjs';
import { skip } from 'rxjs/operators';
import { FavouritesService } from '../../core/favourites.service';
import { EventBus } from '../../core/event-bus';

dayjs.extend(utc);

@Component({
  selector: 'app-place-full-page',
  styleUrls: ['place-full-page.component.scss'],
  templateUrl: 'place-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceFullPageComponent implements OnInit {
  public place?: PlaceDto | null;
  public nearestPlaces?: NearestPlaceDto[];
  public activities?: ActivityPreviewDto[];
  public similarPlaces?: PlacePreviewDto[];
  public connectedRoutes?: RoutePreviewDto[];
  public region?: RegionDto;
  public weather?: PlaceFullWeatherDto;
  public isScheduleExpand = false;
  public isHistoryExpand = false;
  public isDescriptionOpen = false;
  public isSavedInFavourites = false;
  public facts: string[] = [];

  @ViewChild(PlacesMapComponent)
  private mapComponent: PlacesMapComponent;

  @ViewChild(AiChatComponent)
  private aiChatComponent: AiChatComponent;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly regionsApiService: RegionsApiService,
    private readonly activitiesApiService: ActivitiesApiService,
    private readonly routesApiService: RoutesApiService,
    private route: ActivatedRoute,
    public readonly apiHelper: ApiHelper,
    public readonly currencyManager: CurrencyManager,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
    private favouritesService: FavouritesService,
    private eventBus: EventBus,
  ) {
    titleService.setTitle('Abuduba - Place Information');
  }

  public getFacts() {
    return this.facts.map((fact) => fact.trim().replace(/\.$/, ''));
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.place || !this.region) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: this.region.name,
        url: `/regions/${this.region.index}`,
      },
      {
        label: 'Places',
        url: `/places`,
      },
      {
        label: this.place.name,
        url: `/places/${this.place.index}`,
      },
    ];
  }

  get media(): IGalleryFile[] | undefined {
    return this.place?.files.map((f) => ({
      url: f.largeSizeUrl,
      mediumUrl: f.mediumSizeUrl,
      thumbUrl: f.thumbSizeUrl,
    }));
  }

  get buttons() {
    return this.place?.links.filter((l) => !!l.button) || [];
  }

  get links() {
    return this.place?.links.filter((l) => !l.button) || [];
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.route.paramMap.pipe(skip(1)).subscribe(() => {
        this.place = undefined;
        this.changeDetectorRef.detectChanges();
        this.updateData();
      });
    }

    // Handle "favourite-item-added" event
    this.eventBus
      .on<FavouriteItemDto>('favourite-item-added')
      .subscribe((data: FavouriteItemDto) => {
        if (
          data.itemId === this.place?.id &&
          data.itemType === CreateFavouriteItemDtoItemTypeEnum.Place
        ) {
          this.isSavedInFavourites = true; // Update the saved state
          this.changeDetectorRef.detectChanges();
        }
      });

    // Handle "favourite-item-removed" event
    this.eventBus
      .on<{
        listId: number;
        itemId: number;
        itemType: CreateFavouriteItemDtoItemTypeEnum;
      }>('favourite-item-removed')
      .subscribe(
        async (data: {
          listId: number;
          itemId: number;
          itemType: CreateFavouriteItemDtoItemTypeEnum;
        }) => {
          if (
            data.itemId === this.place?.id &&
            data.itemType === CreateFavouriteItemDtoItemTypeEnum.Place
          ) {
            const lists = await firstValueFrom(
              this.favouritesService.getListsOfItem(data.itemId, data.itemType),
            );

            this.isSavedInFavourites = lists.length > 0;
            this.changeDetectorRef.detectChanges();
          }
        },
      );
  }

  openAiChat() {
    this.aiChatComponent.open();
  }

  public get placeWorkingTime(): {
    hours: PlaceDailyOpenHoursDto;
    dayName: string;
  }[] {
    const days = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const schedule = this.place
      ? Object.entries(this.place.workingTime).map(([key, v]) => ({
          hours: v,
          dayName: upperFirst(key),
          dayIndex: days.indexOf(upperFirst(key)),
        }))
      : [];
    return sortBy(schedule, 'dayIndex');
  }

  public renderMap() {
    if (!this.mapComponent) {
      return;
    }

    this.mapComponent.mapboxElement.onMapLoad(() => {
      if (this.place) {
        this.mapComponent.renderPlaces([
          {
            data: this.place,
            highlight: true,
          },
          ...(this.nearestPlaces?.map((p) => ({
            data: p.place,
            distance: p.distance,
          })) || []),
        ]);

        setTimeout(() => {
          this.highlightItemOnMap(this.place!.index, true);
        }, 0);
      }
    });
  }

  public highlightItemOnMap(placeIndex: string, isActive = true) {
    if (isActive) {
      this.mapComponent.closePopup();
      this.mapComponent.moveMapToItem(placeIndex, true);
    }
  }

  private generatePageMeta(data: PlaceDto) {
    const title = `Abuduba - ${data.name}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl(data.files, 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public updateData(): void {
    const params = this.route.snapshot.paramMap;
    const index = String(params.get('index'));

    this.placesApiService
      .getPlaceByIndex({ index })
      .pipe(
        tap((data) => {
          this.place = data;
          this.facts = compact(data.facts);
          this.generatePageMeta(data);
        }),
        switchMap((place) => {
          // Fetch all dependent data in parallel
          return forkJoin({
            activities: this.activitiesApiService.searchByPlace({
              placeId: place.id,
              page: 1,
              limit: 8,
            }),
            region: this.regionsApiService.getRegionById({
              id: place.regionId,
            }),
            connectedRoutes: this.routesApiService.getConnectedToPlace({
              placeId: place.id,
            }),
            nearestPlaces: this.placesApiService.getNearestPlaces({
              index: place.index,
              limit: 15,
            }),
            similarPlaces: this.placesApiService.getSimilarPlaces({
              index: place.index,
              limit: 15,
            }),
            weather: this.placesApiService.getPlaceWeather({
              index: place.index,
            }),
            lists: this.favouritesService.getListsOfItem(
              place.id,
              CreateFavouriteItemDtoItemTypeEnum.Place,
            ),
          });
        }),
        tap(() => {
          setTimeout(() => {
            this.renderMap();
          }, 0);
        }),
        catchError((err) => {
          if (err && err.status === 404) {
            this.place = null;
            this.changeDetectorRef.detectChanges();
            return [];
          } else {
            throw err;
          }
        }),
      )
      .subscribe({
        next: ({
          activities,
          region,
          connectedRoutes,
          nearestPlaces,
          similarPlaces,
          weather,
          lists,
        }) => {
          this.activities = activities.items;
          this.region = region;
          this.connectedRoutes = connectedRoutes;
          this.nearestPlaces = nearestPlaces;
          this.similarPlaces = similarPlaces;
          this.weather = weather;
          this.isSavedInFavourites = lists.length > 0;
          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          console.error('Failed to update data', err);
        },
      });
  }

  protected readonly getActivityName = getActivityName;
  protected readonly getActivityIcon = getActivityIcon;
  protected readonly formatMinutes = formatMinutes;
  protected readonly getCurrentWorkingTime = getCurrentWorkingTime;
  protected readonly isOpenNow = isOpenNow;
  protected readonly formatDistance = formatDistance;
  protected readonly formatWorkingTime = formatWorkingTime;
  protected readonly formatClosedType = formatClosedType;
  protected readonly CreateSuggestionDtoEntityTypeEnum =
    CreateSuggestionDtoEntityTypeEnum;
  protected readonly CreateFavouriteItemDtoItemTypeEnum =
    CreateFavouriteItemDtoItemTypeEnum;
}
