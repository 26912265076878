import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  ReviewsApiService,
  TripAdvisorReviewsListDto,
} from '../api/generated/abuduba-api';

@Component({
  selector: 'app-tripadvisor-reviews',
  templateUrl: 'tripadvisor-reviews.component.html',
  styleUrl: 'tripadvisor-reviews.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TripadvisorReviewsComponent implements OnInit {
  public reviewsData: TripAdvisorReviewsListDto;

  @Input()
  tripadvisorLocationId: number;

  constructor(
    private readonly reviewsApiService: ReviewsApiService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.reviewsApiService
      .getTripadvisorReviews({
        locationId: this.tripadvisorLocationId,
      })
      .subscribe((data) => {
        this.reviewsData = data;
        this.changeDetectorRef.detectChanges();
      });
  }
}
