import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { RestaurantPreviewDto } from '../../core/api/generated/abuduba-api';
import { formatDistance, getMainPictureUrl } from '../../places/places.utils';
import {
  getAwardName,
  getCuisineName,
  getEstablishmentTypeName,
} from '../restaurants.utils';

dayjs.extend(utc);

@Component({
  selector: 'app-restaurant-near-by',
  styleUrls: ['restaurant-near-by.component.scss'],
  templateUrl: 'restaurant-near-by.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestaurantNearByComponent {
  @Input()
  public restaurant: RestaurantPreviewDto;

  @Input()
  public distance?: number;

  getRestaurantAwards() {
    return this.restaurant.awards.slice(0, 3);
  }

  getRestaurantCuisines() {
    return this.restaurant.cuisines.slice(0, 3);
  }

  getRestaurantHighlights() {
    return this.restaurant.highlights.slice(0, 3);
  }

  protected readonly getMainPictureUrl = getMainPictureUrl;
  protected readonly formatDistance = formatDistance;
  protected readonly getAwardName = getAwardName;
  protected readonly getCuisineName = getCuisineName;
  protected readonly getEstablishmentTypeName = getEstablishmentTypeName;
}
