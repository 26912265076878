<app-not-found *ngIf="list === null"></app-not-found>

<div id="favourite_list_card" class="content-wrap" *ngIf="list !== null">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="favourite-list-title-block">
    <div class="favourite-list-title">
      <app-loader
        *ngIf="!list"
        [animation]="false"
        width="300px"
        height="20px"></app-loader>

      <h1 class="value">{{ list?.title }}</h1>
    </div>

    <div class="actions" *ngIf="!isShared">
      <div class="share" (click)="shareList()">
        <ng-icon name="matShare"></ng-icon>
        Share
      </div>

      <div class="remove" (click)="openDeletePopup($event)">
        <ng-icon name="bootstrapTrash"></ng-icon>
        Delete
      </div>
    </div>
  </div>

  <div class="favourite-list-items-block">
    <app-entities-preview-list
      *ngIf="places"
      blockTitle="Saved places"
      [nowrap]="true"
      [places]="places"></app-entities-preview-list>

    <app-entities-preview-list
      *ngIf="routes"
      blockTitle="Saved routes"
      [nowrap]="true"
      [routes]="routes"></app-entities-preview-list>

    <app-entities-preview-list
      *ngIf="restaurants"
      blockTitle="Saved restaurants"
      [nowrap]="true"
      [restaurants]="restaurants"></app-entities-preview-list>
  </div>

  <div
    class="delete-popup-content"
    *ngIf="isDeletedPopupOpened && list"
    (click)="closeDeletePopup()">
    <div
      class="delete-popup"
      (click)="$event.stopPropagation(); $event.preventDefault()">
      <div class="header">
        <div class="actions">
          <div class="close" (click)="closeDeletePopup()">
            <ng-icon name="matCloseOutline"></ng-icon>
          </div>
        </div>
      </div>

      <div class="body">
        <div class="title">Delete this list?</div>
        <div class="list-title">{{ list.title }}</div>

        <div class="error" *ngIf="error">{{ error }}</div>

        <div class="buttons">
          <div class="cancel" (click)="closeDeletePopup()">Cancel</div>
          <div class="create" (click)="delete()">Delete</div>
        </div>
      </div>
    </div>
  </div>
</div>
