import {
  RestaurantDtoAwardsEnum,
  RestaurantDtoCuisinesEnum,
  RestaurantDtoEstablishmentTypesEnum,
  RestaurantDtoHighlightsEnum,
  RestaurantDtoPriceLevelEnum,
} from '../core/api/generated/abuduba-api/shared-enums';

export const restaurantAwards: {
  [key in RestaurantDtoAwardsEnum]: { name: string };
} = {
  MichelinStar: { name: 'Michelin Star' },
  Worlds50BestRestaurants: { name: 'World’s 50 Best Restaurants' },
  JamesBeardAward: { name: 'James Beard Award' },
  WineSpectatorAward: { name: 'Wine Spectator Award' },
  ForbesTravelGuideStars: { name: 'Forbes Travel Guide Stars' },
  TimeOutDubaiRestaurantAward: { name: 'Time Out Dubai Restaurant Award' },
  BBCGoodFoodMiddleEastAward: { name: 'BBC Good Food Middle East Award' },
};

export const restaurantHighlights: {
  [key in RestaurantDtoHighlightsEnum]: { name: string };
} = {
  HiddenGem: { name: 'Hidden Gem' },
  InstagramSpot: { name: 'Instagram Spot' },
  BestForDining: { name: 'Best for Dining' },
  FamilyFriendly: { name: 'Family Friendly' },
  Romantic: { name: 'Romantic' },
  BestForGroups: { name: 'Best for Groups' },
  OutdoorSeating: { name: 'Outdoor Seating' },
  PetFriendly: { name: 'Pet Friendly' },
  LiveMusic: { name: 'Live Music' },
  RooftopView: { name: 'Rooftop View' },
  CasualDining: { name: 'Casual Dining' },
  FineDining: { name: 'Fine Dining' },
  QuickBites: { name: 'Quick Bites' },
  LateNightDining: { name: 'Late Night Dining' },
  VeganFriendly: { name: 'Vegan Friendly' },
  GlutenFreeOptions: { name: 'Gluten-Free Options' },
  KidFriendly: { name: 'Kid Friendly' },
  ScenicView: { name: 'Scenic View' },
};

export const restaurantCuisines: {
  [key in RestaurantDtoCuisinesEnum]: { name: string };
} = {
  Afghan: { name: 'Afghan' },
  African: { name: 'African' },
  American: { name: 'American' },
  Arabic: { name: 'Arabic' },
  Argentinean: { name: 'Argentinean' },
  Armenian: { name: 'Armenian' },
  Asian: { name: 'Asian' },
  Assyrian: { name: 'Assyrian' },
  Australian: { name: 'Australian' },
  Bangladeshi: { name: 'Bangladeshi' },
  Bar: { name: 'Bar' },
  Barbecue: { name: 'Barbecue' },
  Belgian: { name: 'Belgian' },
  Brazilian: { name: 'Brazilian' },
  BrewPub: { name: 'Brew Pub' },
  British: { name: 'British' },
  Burmese: { name: 'Burmese' },
  Cafe: { name: 'Cafe' },
  CajunAndCreole: { name: 'Cajun & Creole' },
  Campania: { name: 'Campania' },
  Cantonese: { name: 'Cantonese' },
  Caribbean: { name: 'Caribbean' },
  CentralAmerican: { name: 'Central American' },
  CentralAsian: { name: 'Central Asian' },
  CentralEuropean: { name: 'Central European' },
  Chinese: { name: 'Chinese' },
  Contemporary: { name: 'Contemporary' },
  Deli: { name: 'Deli' },
  Diner: { name: 'Diner' },
  Diningbars: { name: 'Dining Bars' },
  EasternEuropean: { name: 'Eastern European' },
  Egyptian: { name: 'Egyptian' },
  Ethiopian: { name: 'Ethiopian' },
  European: { name: 'European' },
  FastFood: { name: 'Fast Food' },
  Filipino: { name: 'Filipino' },
  French: { name: 'French' },
  Fusion: { name: 'Fusion' },
  Gastropub: { name: 'Gastro Pub' },
  Georgian: { name: 'Georgian' },
  German: { name: 'German' },
  Greek: { name: 'Greek' },
  Hawaiian: { name: 'Hawaiian' },
  Healthy: { name: 'Healthy' },
  HongKong: { name: 'Hong Kong' },
  Indian: { name: 'Indian' },
  Indonesian: { name: 'Indonesian' },
  International: { name: 'International' },
  Irish: { name: 'Irish' },
  Italian: { name: 'Italian' },
  Japanese: { name: 'Japanese' },
  JapaneseFusion: { name: 'Japanese Fusion' },
  Korean: { name: 'Korean' },
  Latin: { name: 'Latin' },
  Lebanese: { name: 'Lebanese' },
  Malaysian: { name: 'Malaysian' },
  Mediterranean: { name: 'Mediterranean' },
  Mexican: { name: 'Mexican' },
  MiddleEastern: { name: 'Middle Eastern' },
  Mongolian: { name: 'Mongolian' },
  Moroccan: { name: 'Moroccan' },
  Neapolitan: { name: 'Neapolitan' },
  Pakistani: { name: 'Pakistani' },
  Persian: { name: 'Persian' },
  Peruvian: { name: 'Peruvian' },
  Pizza: { name: 'Pizza' },
  Portuguese: { name: 'Portuguese' },
  Pub: { name: 'Pub' },
  Romanian: { name: 'Romanian' },
  Seafood: { name: 'Seafood' },
  Singaporean: { name: 'Singaporean' },
  SouthAmerican: { name: 'South American' },
  Southwestern: { name: 'Southwestern' },
  Spanish: { name: 'Spanish' },
  SriLankan: { name: 'Sri Lankan' },
  Steakhouse: { name: 'Steakhouse' },
  StreetFood: { name: 'Street Food' },
  Sushi: { name: 'Sushi' },
  Szechuan: { name: 'Szechuan' },
  Thai: { name: 'Thai' },
  Turkish: { name: 'Turkish' },
  Tuscan: { name: 'Tuscan' },
  Uzbek: { name: 'Uzbek' },
  Vietnamese: { name: 'Vietnamese' },
  WineBar: { name: 'Wine Bar' },
};

export const restaurantPriceLevels: {
  [key in RestaurantDtoPriceLevelEnum]: { name: string };
} = {
  Cheap: { name: '$ - cheap' },
  Medium: { name: '$$ - medium' },
  Expensive: { name: '$$$ - expensive' },
};

export const restaurantEstablishmentTypes: {
  [key in RestaurantDtoEstablishmentTypesEnum]: { name: string };
} = {
  Cafe: { name: 'Cafe' },
  Restaurant: { name: 'Restaurant' },
  Bakery: { name: 'Bakery' },
  BarAndPub: { name: 'Bar & Pub' },
  QuickBite: { name: 'Quick bite' },
  Dessert: { name: 'Dessert' },
};
