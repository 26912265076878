/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createList } from '../fn/favourite/create-list';
import { CreateList$Params } from '../fn/favourite/create-list';
import { createListItem } from '../fn/favourite/create-list-item';
import { CreateListItem$Params } from '../fn/favourite/create-list-item';
import { deleteItem } from '../fn/favourite/delete-item';
import { DeleteItem$Params } from '../fn/favourite/delete-item';
import { deleteItemById } from '../fn/favourite/delete-item-by-id';
import { DeleteItemById$Params } from '../fn/favourite/delete-item-by-id';
import { deleteListById } from '../fn/favourite/delete-list-by-id';
import { DeleteListById$Params } from '../fn/favourite/delete-list-by-id';
import { FavouriteItemDto } from '../models/favourite-item-dto';
import { FavouriteListDto } from '../models/favourite-list-dto';
import { FavouriteListLinkDto } from '../models/favourite-list-link-dto';
import { getListItems } from '../fn/favourite/get-list-items';
import { GetListItems$Params } from '../fn/favourite/get-list-items';
import { getLists } from '../fn/favourite/get-lists';
import { GetLists$Params } from '../fn/favourite/get-lists';
import { getSharedLink } from '../fn/favourite/get-shared-link';
import { GetSharedLink$Params } from '../fn/favourite/get-shared-link';
import { getSharedList } from '../fn/favourite/get-shared-list';
import { GetSharedList$Params } from '../fn/favourite/get-shared-list';
import { getSharedListItems } from '../fn/favourite/get-shared-list-items';
import { GetSharedListItems$Params } from '../fn/favourite/get-shared-list-items';
import { updateList } from '../fn/favourite/update-list';
import { UpdateList$Params } from '../fn/favourite/update-list';

@Injectable({ providedIn: 'root' })
export class FavouriteApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `createListItem()` */
  static readonly CreateListItemPath = '/api/v1/favourite/item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createListItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createListItem$Response(
    params: CreateListItem$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<FavouriteItemDto>> {
    return createListItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createListItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createListItem(
    params: CreateListItem$Params,
    context?: HttpContext,
  ): Observable<FavouriteItemDto> {
    return this.createListItem$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<FavouriteItemDto>): FavouriteItemDto => r.body,
      ),
    );
  }

  /** Path part for operation `deleteItem()` */
  static readonly DeleteItemPath = '/api/v1/favourite/item';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteItem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteItem$Response(
    params: DeleteItem$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    return deleteItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteItem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteItem(
    params: DeleteItem$Params,
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `getLists()` */
  static readonly GetListsPath = '/api/v1/favourite/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLists()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLists$Response(
    params?: GetLists$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<FavouriteListDto>>> {
    return getLists(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLists(
    params?: GetLists$Params,
    context?: HttpContext,
  ): Observable<Array<FavouriteListDto>> {
    return this.getLists$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<FavouriteListDto>>,
        ): Array<FavouriteListDto> => r.body,
      ),
    );
  }

  /** Path part for operation `createList()` */
  static readonly CreateListPath = '/api/v1/favourite/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createList$Response(
    params: CreateList$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<FavouriteListDto>> {
    return createList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createList(
    params: CreateList$Params,
    context?: HttpContext,
  ): Observable<FavouriteListDto> {
    return this.createList$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<FavouriteListDto>): FavouriteListDto => r.body,
      ),
    );
  }

  /** Path part for operation `deleteListById()` */
  static readonly DeleteListByIdPath = '/api/v1/favourite/list/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteListById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteListById$Response(
    params: DeleteListById$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    return deleteListById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteListById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteListById(
    params: DeleteListById$Params,
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteListById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `updateList()` */
  static readonly UpdateListPath = '/api/v1/favourite/list/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateList()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateList$Response(
    params: UpdateList$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<FavouriteListDto>> {
    return updateList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateList$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateList(
    params: UpdateList$Params,
    context?: HttpContext,
  ): Observable<FavouriteListDto> {
    return this.updateList$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<FavouriteListDto>): FavouriteListDto => r.body,
      ),
    );
  }

  /** Path part for operation `getListItems()` */
  static readonly GetListItemsPath = '/api/v1/favourite/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getListItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListItems$Response(
    params?: GetListItems$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<FavouriteItemDto>>> {
    return getListItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getListItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getListItems(
    params?: GetListItems$Params,
    context?: HttpContext,
  ): Observable<Array<FavouriteItemDto>> {
    return this.getListItems$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<FavouriteItemDto>>,
        ): Array<FavouriteItemDto> => r.body,
      ),
    );
  }

  /** Path part for operation `deleteItemById()` */
  static readonly DeleteItemByIdPath = '/api/v1/favourite/item/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteItemById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteItemById$Response(
    params: DeleteItemById$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<void>> {
    return deleteItemById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteItemById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteItemById(
    params: DeleteItemById$Params,
    context?: HttpContext,
  ): Observable<void> {
    return this.deleteItemById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body),
    );
  }

  /** Path part for operation `getSharedLink()` */
  static readonly GetSharedLinkPath = '/api/v1/favourite/list/{listId}/share';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSharedLink()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedLink$Response(
    params: GetSharedLink$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<FavouriteListLinkDto>> {
    return getSharedLink(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSharedLink$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedLink(
    params: GetSharedLink$Params,
    context?: HttpContext,
  ): Observable<FavouriteListLinkDto> {
    return this.getSharedLink$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<FavouriteListLinkDto>): FavouriteListLinkDto =>
          r.body,
      ),
    );
  }

  /** Path part for operation `getSharedList()` */
  static readonly GetSharedListPath = '/api/v1/favourite/list/shared/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSharedList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedList$Response(
    params: GetSharedList$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<FavouriteListDto>> {
    return getSharedList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSharedList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedList(
    params: GetSharedList$Params,
    context?: HttpContext,
  ): Observable<FavouriteListDto> {
    return this.getSharedList$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<FavouriteListDto>): FavouriteListDto => r.body,
      ),
    );
  }

  /** Path part for operation `getSharedListItems()` */
  static readonly GetSharedListItemsPath =
    '/api/v1/favourite/list/shared/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSharedListItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedListItems$Response(
    params: GetSharedListItems$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<FavouriteItemDto>>> {
    return getSharedListItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSharedListItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSharedListItems(
    params: GetSharedListItems$Params,
    context?: HttpContext,
  ): Observable<Array<FavouriteItemDto>> {
    return this.getSharedListItems$Response(params, context).pipe(
      map(
        (
          r: StrictHttpResponse<Array<FavouriteItemDto>>,
        ): Array<FavouriteItemDto> => r.body,
      ),
    );
  }
}
