import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FavouriteItemDto,
  PlacePreviewDto,
  PlacesApiService,
  RegionDto,
  RegionsApiService,
  RouteDto,
  RoutePlaceMetaDto,
  RoutePreviewDto,
  RoutesApiService,
  RouteStepDto,
} from '../../core/api/generated/abuduba-api';
import { ActivatedRoute } from '@angular/router';
import { IGalleryFile } from '../../core/gallery/gallery.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import mapboxgl from 'mapbox-gl';
import {
  formatDistance,
  formatMinutes,
  getMainPictureUrl,
} from '../../places/places.utils';
import { getTypeIcon, getTypeName } from '../routes.utils';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { ApiHelper } from '../../core/api/api.helper';
import { orderBy } from 'lodash';
import {
  CreateFavouriteItemDtoItemTypeEnum,
  CreateSuggestionDtoEntityTypeEnum,
  RouteDtoTypeEnum,
} from '../../core/api/generated/abuduba-api/shared-enums';
import { firstValueFrom, forkJoin, of, switchMap } from 'rxjs';
import { skip } from 'rxjs/operators';
import { FavouritesService } from '../../core/favourites.service';
import { EventBus } from '../../core/event-bus';
import { PlacesMapComponent } from '../../core/places-map/places-map.component';

dayjs.extend(utc);

@Component({
  selector: 'app-route-full-page',
  styleUrls: ['route-full-page.component.scss'],
  templateUrl: 'route-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouteFullPageComponent implements OnInit {
  public route?: RouteDto | null;
  public places: PlacePreviewDto[] = [];
  public placesMap?: Map<number, PlacePreviewDto>;
  public nearestRoutes?: (RoutePreviewDto & { distance?: number })[];
  public region?: RegionDto;

  @ViewChild(PlacesMapComponent)
  private mapComponent?: PlacesMapComponent;

  public points: [number, number][] = [];
  public markers: mapboxgl.Marker[] = [];
  public geometry: [number, number][] = [];
  public isSavedInFavourites = false;

  private routeSourceId = 'route';
  private routeLineLayerId = 'route-line';

  private routeHikingSourceId = 'hiking-trail-source';
  private routeHikingLineLayerId = 'hiking-trail-layer';
  private routeHikingArrowsLayerId = 'trail-arrow-layer';

  public currentTab = 'mapbox';
  public wikilocHtml?: SafeHtml;
  public wikilocLoading = false;

  constructor(
    private readonly placesApiService: PlacesApiService,
    private readonly routesApiService: RoutesApiService,
    private readonly regionsApiService: RegionsApiService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private apiHelper: ApiHelper,
    private favouritesService: FavouritesService,
    private eventBus: EventBus,
    public domSanitizer: DomSanitizer,
    public appRef: ApplicationRef,
  ) {
    titleService.setTitle('Abuduba - Route Information');
  }

  public get googleMapUrl() {
    return `https://www.google.com/maps?q=${this.route?.location.y},${this.route?.location.x}`;
  }

  public setTab(tab: string) {
    if (this.currentTab === tab) {
      return;
    }

    this.currentTab = tab;

    if (tab === 'mapbox') {
      setTimeout(() => {
        this.initMap();
      }, 100);
    }

    if (tab === 'wikiloc') {
      this.wikilocLoading = true;
      this.appRef.tick();
      const iframe = document.querySelector(
        '#wikiloc .content iframe',
      ) as HTMLIFrameElement;

      if (iframe) {
        iframe.addEventListener('load', () => {
          this.wikilocLoading = false;
          this.changeDetectorRef.detectChanges();
        });
      }
    }
  }

  public get isHiking() {
    return this.route?.type === RouteDtoTypeEnum.Hiking;
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.route || !this.region) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: this.region.name,
        url: `/regions/${this.region.index}`,
      },
      {
        label: 'Routes',
        url: `/routes`,
      },
      {
        label: this.route.name,
        url: `/routes/${this.route.index}`,
      },
    ];
  }

  get media(): IGalleryFile[] | undefined {
    return this.route?.files.map((f) => ({
      url: f.largeSizeUrl,
      mediumUrl: f.mediumSizeUrl,
      thumbUrl: f.thumbSizeUrl,
    }));
  }

  get buttons() {
    return this.route?.links.filter((l) => !!l.button) || [];
  }

  get links() {
    return this.route?.links.filter((l) => !l.button) || [];
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.activatedRoute.paramMap.pipe(skip(1)).subscribe(() => {
        this.route = undefined;
        this.changeDetectorRef.detectChanges();
        this.updateData();
      });
    }

    // Handle "favourite-item-added" event
    this.eventBus
      .on<FavouriteItemDto>('favourite-item-added')
      .subscribe((data: FavouriteItemDto) => {
        if (
          data.itemId === this.route?.id &&
          data.itemType === CreateFavouriteItemDtoItemTypeEnum.Route
        ) {
          this.isSavedInFavourites = true; // Update the saved state
          this.changeDetectorRef.detectChanges();
        }
      });

    // Handle "favourite-item-removed" event
    this.eventBus
      .on<{
        listId: number;
        itemId: number;
        itemType: CreateFavouriteItemDtoItemTypeEnum;
      }>('favourite-item-removed')
      .subscribe(
        async (data: {
          listId: number;
          itemId: number;
          itemType: CreateFavouriteItemDtoItemTypeEnum;
        }) => {
          if (
            data.itemId === this.route?.id &&
            data.itemType === CreateFavouriteItemDtoItemTypeEnum.Route
          ) {
            const lists = await firstValueFrom(
              this.favouritesService.getListsOfItem(data.itemId, data.itemType),
            );

            this.isSavedInFavourites = lists.length > 0;
            this.changeDetectorRef.detectChanges();
          }
        },
      );
  }

  getStepPlaces(step: RouteStepDto): PlacePreviewDto[] {
    const places = [];

    for (const id of step.placeIds) {
      const foundPlace = this.placesMap?.get(id);

      if (foundPlace) {
        places.push(foundPlace);
      }
    }

    return places;
  }

  getPlacesMeta(): RoutePlaceMetaDto[] {
    const maxLength = 5;

    const list = orderBy(this.places, (p) => p.name.length, 'asc')
      .slice(0, maxLength)
      .map((p) => ({
        name: p.name,
        id: p.id,
      }));

    if (list.length < this.places.length) {
      list.push({
        name: `+${this.places.length - maxLength}`,
        id: 0,
      });
    }

    return list;
  }

  private generatePageMeta(data: RouteDto) {
    const title = `Abuduba - ${data.name}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl(data.files, 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public initMap(): void {
    // If in browser, set map route
    if (this.apiHelper.isBrowser && this.route) {
      if (this.isHiking) {
        this.drawMapHikingRoute(this.route);
      } else {
        this.drawMapRoute(this.route);
      }
    }
  }

  public updateData(): void {
    const params = this.activatedRoute.snapshot.paramMap;
    const index = String(params.get('index'));

    this.routesApiService
      .getRouteByIndex({ index })
      .pipe(
        switchMap((routeData) => {
          this.route = routeData;
          this.wikilocHtml = this.domSanitizer.bypassSecurityTrustHtml(
            routeData?.wikilocEmbedMap || '',
          );
          this.generatePageMeta(routeData);

          setTimeout(() => {
            this.initMap();
          }, 100);

          // Fetch related data in parallel
          return forkJoin({
            region: this.regionsApiService.getRegionById({
              id: routeData.regionId,
            }),
            places:
              routeData.placeIds.length > 0
                ? this.placesApiService.getPlacesPreview({
                    ids: routeData.placeIds,
                  })
                : of([]),
            lists: this.favouritesService.getListsOfItem(
              routeData.id,
              CreateFavouriteItemDtoItemTypeEnum.Route,
            ),
            nearestRoutes: this.routesApiService.getNearestRoutes({
              index: routeData.index,
              limit: 3,
            }),
          });
        }),
      )
      .subscribe({
        next: ({ region, places, nearestRoutes, lists }) => {
          // Update state with fetched data
          this.region = region;

          this.placesMap = new Map(places.map((place) => [place.id, place]));
          this.places = places;

          this.nearestRoutes = nearestRoutes.map((r) => ({
            ...r.route,
            distance: r.distance,
          }));

          this.isSavedInFavourites = lists.length > 0;

          this.changeDetectorRef.detectChanges();
        },
        error: (err) => {
          if (err?.status === 404) {
            this.route = null;
            this.changeDetectorRef.detectChanges();
          } else {
            console.error('Error fetching route data:', err);
          }
        },
      });
  }

  public cleanMap(e?: Event) {
    const map = this.mapComponent?.mapboxElement.map;

    if (!map) {
      return;
    }

    this.points = [];

    for (const marker of this.markers) {
      marker.remove();
    }

    this.markers = [];

    const sourceIds = [this.routeSourceId, this.routeHikingSourceId];
    const layerIds = [
      this.routeLineLayerId,
      this.routeHikingLineLayerId,
      this.routeHikingArrowsLayerId,
    ];

    for (const layerId of layerIds) {
      if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
      }
    }

    for (const sourceId of sourceIds) {
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    }

    if (e) {
      e.preventDefault();
    }
  }

  public drawMapRoute(route: RouteDto) {
    const mapComponent = this.mapComponent?.mapboxElement;
    const map = mapComponent?.map;

    if (!map) {
      return;
    }

    mapComponent.onMapLoad(() => {
      this.cleanMap();
      mapComponent.resize();

      let source = map.getSource(this.routeSourceId) as mapboxgl.GeoJSONSource;
      const layer = map.getLayer(this.routeLineLayerId) as mapboxgl.LineLayer;

      for (const marker of this.markers) {
        marker.remove();
      }
      this.markers = [];

      if (!source) {
        source = map
          .addSource(this.routeSourceId, {
            type: 'geojson',
            data: {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: [],
              },
            },
          })
          .getSource(this.routeSourceId) as mapboxgl.GeoJSONSource;
      }

      if (!layer) {
        map.addLayer({
          id: this.routeLineLayerId,
          type: 'line',
          source: this.routeSourceId,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#4264fb',
            'line-width': 4,
          },
        });
      }

      let index = 1;
      for (const p of route.points) {
        const coordinates = {
          lng: p.x,
          lat: p.y,
        };
        const step = route.steps[index - 1];
        const places = this.getStepPlaces(step);

        const html = `
          <div class="step-marker">
          <div class="title">${index++}</div>
          <div class="places">${places
            .map(
              (p) =>
                `<div style='background: url(${getMainPictureUrl(p.files, 'thumb')}) no-repeat center center'></div>`,
            )
            .slice(0, 4)
            .join('')}</div>
          </div>`;

        // Create a HTML element for each marker
        const markerElement = document.createElement('div');
        markerElement.innerHTML = html;
        markerElement.style.zIndex = '1';
        markerElement.style.width = '50px';
        markerElement.style.height = '50px';
        markerElement.style.borderRadius = '50%';
        markerElement.style.overflow = 'hidden';

        markerElement.addEventListener('mouseenter', () => {
          markerElement.style.zIndex = '3';
        });

        markerElement.addEventListener('mouseout', () => {
          markerElement.style.zIndex = '1';
        });

        const marker = new mapboxgl.Marker(markerElement)
          .setLngLat(coordinates)
          .addTo(map);
        this.markers.push(marker);
      }

      source.setData({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: route.points.map((p) => [p.x, p.y]),
        },
        properties: {},
      });
    });
  }

  public async drawMapHikingRoute(route: RouteDto) {
    const mapComponent = this.mapComponent?.mapboxElement;
    const map = mapComponent?.map;

    if (!map) {
      return;
    }

    mapComponent?.onMapLoad(() => {
      this.cleanMap();
      mapComponent.resize();
      const geometry = route.geometry || [];

      if (geometry.length < 2) {
        console.error('At least two points are required to draw a route.');
        return;
      }

      // Add the source if it doesn't exist
      let source = map.getSource(
        this.routeHikingSourceId,
      ) as mapboxgl.GeoJSONSource;

      if (!source) {
        map.addSource(this.routeHikingSourceId, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [],
            },
          },
        });
        source = map.getSource(
          this.routeHikingSourceId,
        ) as mapboxgl.GeoJSONSource;
      }

      // Add the line layer if it doesn't exist
      if (!map.getLayer(this.routeHikingLineLayerId)) {
        map.addLayer({
          id: this.routeHikingLineLayerId,
          type: 'line',
          source: this.routeHikingSourceId,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#3366ff', // Green line
            'line-width': 4,
          },
        });
      }

      // Add directional arrows
      if (!map.getLayer(this.routeHikingArrowsLayerId)) {
        map.addLayer({
          id: this.routeHikingArrowsLayerId,
          type: 'symbol',
          source: this.routeHikingSourceId,
          layout: {
            'symbol-placement': 'line',
            'symbol-spacing': 50, // Space between arrows
            'text-field': '→', // Unicode arrow character
            'text-size': 17, // Arrow size
            'text-rotate': 0, // Auto-align arrows to path
            'text-offset': [0, -0.1], // Offset slightly above the line
          },
          paint: {
            'text-color': 'white', // Arrow color
            // 'text-halo-color': 'black', // Optional: Outline color for better visibility
            // 'text-halo-width': 1,
          },
        });
      }

      const coordinates = geometry.map((p) => [p.x, p.y]);

      // Add a marker at the starting point of the route
      const startMarker = new mapboxgl.Marker({ color: 'green' })
        .setLngLat(coordinates[0] as [number, number])
        .addTo(map);

      this.markers.push(startMarker);

      // Update the source with the new coordinates
      source.setData({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates,
        },
        properties: {},
      });
    });
  }

  protected readonly getTypeIcon = getTypeIcon;
  protected readonly getTypeName = getTypeName;
  protected readonly formatMinutes = formatMinutes;
  protected readonly formatDistance = formatDistance;
  protected readonly CreateSuggestionDtoEntityTypeEnum =
    CreateSuggestionDtoEntityTypeEnum;
  protected readonly CreateFavouriteItemDtoItemTypeEnum =
    CreateFavouriteItemDtoItemTypeEnum;
}
