<div class="chat-window-block">
  <div class="chat-window" [class.closed]="_isClosed">
    <div class="header">
      <div class="title">
        <ng-icon name="ionSparklesSharp"></ng-icon>
        <div>{{ title }}</div>
      </div>
      <div class="actions">
        <div class="action close" title="Close" (click)="close()">
          <ng-icon name="matCloseOutline"></ng-icon>
        </div>
      </div>
    </div>

    <div class="content" #content>
      <div
        class="message"
        [class.my-message]="msg.isMe"
        [class]="msg.class"
        *ngFor="let msg of messages">
        <div class="message-meta">
          <div class="sender-name">{{ msg.senderName }}</div>
          <div class="date">{{ msg.date | date: 'MMMM d, y' }}</div>
        </div>

        <div class="message-body" *ngIf="msg.isMe">{{ msg.message }}</div>
        <div
          class="message-body"
          *ngIf="!msg.isMe"
          [innerHTML]="msg.message"></div>

        <div class="message-places" *ngIf="msg.places && msg.places.length > 0">
          <app-place-near-by
            *ngFor="let place of msg.places"
            [place]="place"></app-place-near-by>
        </div>
      </div>
    </div>

    <div class="in-progress" *ngIf="inProgress">
      Just a moment, gathering details...
    </div>

    <div class="error" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>

    <div class="footer">
      <div class="input-block">
        <input
          type="text"
          placeholder="Type a message"
          [(ngModel)]="userMessage"
          (keydown.enter)="sendMessage()" />
        <button
          [disabled]="!userMessage.trim() || inProgress"
          (click)="sendMessage()">
          <ng-icon name="matSendOutline"></ng-icon>
        </button>
      </div>
    </div>
  </div>

  <div
    id="ai_chat_floating_button"
    title="Speak with AI"
    [class.closed]="!_isClosed"
    (click)="open()">
    <ng-icon name="ionSparklesSharp"></ng-icon>
  </div>
</div>
