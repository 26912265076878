<div class="restaurant-preview" [class.horizontal]="horizontal">
  <a
    class="photo"
    routerLink="/restaurants/{{ restaurant.index }}"
    [target]="newTab ? '_blank' : '_self'">
    <app-gallery [files]="media" [preview]="true"></app-gallery>

    <div class="michelin" *ngIf="isMichelinStar()">
      <ng-icon name="matStarOutline"></ng-icon>
      Michelin Star
    </div>

    <app-favourite-popup
      [textMode]="false"
      [itemId]="restaurant.id"
      [saved]="saved"
      [itemType]="
        CreateFavouriteItemDtoItemTypeEnum.Restaurant
      "></app-favourite-popup>
  </a>

  <div class="info">
    <a
      class="title"
      routerLink="/restaurants/{{ restaurant.index }}"
      [target]="newTab ? '_blank' : '_self'">
      <h3>
        {{ restaurant.name }}
      </h3>
      <div class="region">{{ restaurant.regionName }}</div>
    </a>

    <!--      <div class="rating-number" *ngIf="restaurant.rating">-->
    <!--        <ng-icon name="matStarOutline"></ng-icon>-->
    <!--        <div class="value">{{ restaurant.rating! }}</div>-->
    <!--      </div>-->

    <div
      class="awards"
      *ngIf="restaurant.awards && restaurant.awards.length > 0">
      <div
        class="award"
        *ngFor="let a of getRestaurantAwards()"
        [title]="getAwardName(a)">
        <span>{{ getAwardName(a) }}</span>
      </div>
    </div>

    <div class="short-info">
      <div class="price-level">
        <ng-icon name="matPaidOutline"></ng-icon>
        <div>
          {{ getPriceLevelName(restaurant.priceLevel) }}
        </div>
      </div>

      <div class="cuisines">
        <ng-icon name="phosphorBowlFood"></ng-icon>
        <div>
          {{
            restaurant.establishmentTypes
              .slice(0, 3)
              .map(getEstablishmentTypeName)
              .join(', ')
          }}
        </div>
      </div>
    </div>

    <div
      class="description"
      [innerHTML]="removeHtmlTags(restaurant.description)"></div>

    <div class="rating">
      <app-rating-selector
        [tripadvisor]="restaurant.tripadvisorData"
        [small]="false"
        [textOnly]="!horizontal"
        [google]="restaurant.googleData"></app-rating-selector>
    </div>

    <div
      class="highlights"
      *ngIf="horizontal && restaurant.highlights && restaurant.highlights[0]">
      {{ restaurant.highlights.slice(0, 3).map(getHighlightName).join(', ') }}
    </div>
  </div>
</div>
