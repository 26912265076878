import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { ActivityPreviewDto } from '../../core/api/generated/abuduba-api';
import { CurrencyManager } from '../../core/currency-manager';
import {
  formatActivityDuration,
  getActivityDestinations,
  isActivityPickupIncluded,
} from '../activities.utils';
import { IGalleryFile } from '../../core/gallery/gallery.component';

@Component({
  selector: 'app-activity-preview',
  styleUrls: ['activity-preview.component.scss'],
  templateUrl: 'activity-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityPreviewComponent {
  @Input()
  public activity: ActivityPreviewDto;

  @Input()
  public horizontal = false;

  @Input()
  public newTab = false;

  constructor(
    public readonly currencyManager: CurrencyManager,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.currencyManager.currentCurrency$.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  get media(): IGalleryFile[] {
    return this.activity?.images.map((f) => ({
      url: f.originalUrl,
      mediumUrl: f.smallUrl || f.originalUrl,
      thumbUrl: f.smallUrl || f.originalUrl,
    }));
  }

  protected readonly formatActivityDuration = formatActivityDuration;
  protected readonly getActivityDestinations = getActivityDestinations;
  protected readonly isActivityPickupIncluded = isActivityPickupIncluded;
}
