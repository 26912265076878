import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { PlacePreviewDto } from '../../core/api/generated/abuduba-api';
import {
  formatClosedType,
  formatMinutes,
  getActivityIcon,
  getActivityName,
  getMainPictureUrl,
  removeHtmlTags,
} from '../places.utils';
import { CurrencyManager } from '../../core/currency-manager';
import { CreateFavouriteItemDtoItemTypeEnum } from '../../core/api/generated/abuduba-api/shared-enums';
import { IGalleryFile } from '../../core/gallery/gallery.component';

@Component({
  selector: 'app-place-preview',
  styleUrls: ['place-preview.component.scss'],
  templateUrl: 'place-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacePreviewComponent {
  @Input()
  public place: PlacePreviewDto;

  @Input()
  public horizontal = false;

  @Input()
  public newTab = false;

  @Input()
  public saved = false;

  protected readonly getActivityName = getActivityName;
  protected readonly formatMinutes = formatMinutes;
  protected readonly getMainPictureUrl = getMainPictureUrl;

  getPlaceActivities() {
    return this.horizontal
      ? this.place.activities
      : this.place.activities.slice(0, 3);
  }

  protected readonly getActivityIcon = getActivityIcon;
  protected readonly removeHtmlTags = removeHtmlTags;

  constructor(
    public readonly currencyManager: CurrencyManager,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.currencyManager.currentCurrency$.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  get media(): IGalleryFile[] {
    return this.place?.files.map((f) => ({
      url: f.originalUrl,
      mediumUrl: f.mediumSizeUrl,
      thumbUrl: f.smallSizeUrl,
    }));
  }

  protected readonly formatClosedType = formatClosedType;
  protected readonly CreateFavouriteItemDtoItemTypeEnum =
    CreateFavouriteItemDtoItemTypeEnum;
}
