<div class="route-preview" [class.horizontal]="horizontal">
  <a
    class="photo"
    routerLink="/routes/{{ route.index }}"
    [target]="newTab ? '_blank' : '_self'">
    <app-gallery [files]="media" [preview]="true"></app-gallery>

    <app-favourite-popup
      [textMode]="false"
      [itemId]="route.id"
      [saved]="saved"
      [itemType]="
        CreateFavouriteItemDtoItemTypeEnum.Route
      "></app-favourite-popup>
  </a>

  <div class="info">
    <div class="title-block">
      <a
        class="title-value"
        routerLink="/routes/{{ route.index }}"
        [target]="newTab ? '_blank' : '_self'">
        <h3>{{ route.name }}</h3>
      </a>
      <div class="subtitle-info">
        <div class="type">
          {{ getTypeName(route.type) }}
        </div>
        <span class="dot"></span>
        <span class="value">{{ formatDistance(route.distance) }}</span>
        <span class="dot"></span>
        <div class="region">{{ route.regionName }}</div>
      </div>
    </div>

    <div class="labels" *ngIf="getPlacesMeta().length > 0">
      <div class="label place" *ngFor="let p of getPlacesMeta()">
        <span>{{ p.name }}</span>
      </div>
    </div>

    <div class="short-info">
      <div class="duration" title="Duration" *ngIf="route.duration">
        <ng-icon name="matHourglassTopOutline"></ng-icon>
        <div>{{ formatMinutes(route.duration) }}</div>
      </div>

      <div class="distance" title="Distance" *ngIf="route.distance">
        <ng-icon name="matRouteOutline"></ng-icon>
        <div>{{ formatDistance(route.distance) }}</div>
      </div>

      <div class="elevation" title="Elevation gain" *ngIf="isHiking">
        <ng-icon name="bootstrapGraphUp"></ng-icon>
        <div>{{ route.elevationTotalGain }}m</div>
      </div>

      <div class="difficulty" title="Difficulty level" *ngIf="isHiking">
        <ng-icon name="bootstrapHeartPulseFill"></ng-icon>
        <div>{{ route.difficultyLevel }}</div>
      </div>

      <div
        class="loop"
        title="Loop trail"
        *ngIf="isHiking && route.isLoopTrail">
        <ng-icon name="bootstrapRepeat"></ng-icon>
        <div>Loop</div>
      </div>

      <div class="places" title="Places" *ngIf="route.placeIds.length > 0">
        <ng-icon name="matPlaceOutline"></ng-icon>
        <div>{{ route.placeIds.length }} places</div>
      </div>
    </div>

    <div
      class="description"
      [innerHTML]="removeHtmlTags(route.description)"></div>
  </div>
</div>
