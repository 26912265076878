<div class="app-gallery" *ngIf="files.length > 0 && !preview">
  <div class="main-image" (click)="openImage(0)">
    <img [ngSrc]="mainImage.mediumUrl" fill alt="Main image" />
  </div>

  <div class="side-images">
    <div
      class="side-image-block"
      *ngFor="let img of sideImages; index as i; trackBy: imageTrackBy"
      (click)="openImage(+i + 1)">
      <img [ngSrc]="img.mediumUrl" fill alt="Side image" />
    </div>
  </div>

  <div class="show-all" (click)="openImage(0)">
    Show all {{ files.length }} photos
  </div>
</div>

<div
  class="app-gallery-preview"
  *ngIf="files.length > 0 && preview && apiHelper.isBrowser">
  <swiper-container
    #swiper
    init="false"
    speed="500"
    pagination="true"
    pagination-dynamic-bullets="true"
    space-between="20"
    css-mode="true"
    navigation="true"
    class="gallery-swiper">
    <swiper-slide *ngFor="let img of files; index as i">
      <div class="slider-container">
        <img [ngSrc]="img.thumbUrl" fill alt="Image" loading="lazy" />
      </div>
    </swiper-slide>
  </swiper-container>
</div>
