import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  ArticleDto,
  ArticlesApiService,
} from '../../core/api/generated/abuduba-api';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getMainPictureUrl } from '../../places/places.utils';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';
import { Meta, Title } from '@angular/platform-browser';
import { ApiHelper } from '../../core/api/api.helper';
import { skip } from 'rxjs/operators';
import { getCategoryName } from '../articles.utils';

dayjs.extend(utc);

@Component({
  selector: 'app-article-full-page',
  styleUrls: ['article-full-page.component.scss'],
  templateUrl: 'article-full-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleFullPageComponent implements OnInit {
  public article?: ArticleDto | null;

  constructor(
    private readonly articlesApiService: ArticlesApiService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private apiHelper: ApiHelper,
  ) {
    titleService.setTitle('Abuduba - Article Information');
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.article) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Articles',
        url: `/article`,
      },
      {
        label: this.article.title,
        url: `/article/${this.article.index}`,
      },
    ];
  }

  ngOnInit(): void {
    this.updateData();

    if (this.apiHelper.isBrowser) {
      this.activatedRoute.paramMap.pipe(skip(1)).subscribe(() => {
        this.article = undefined;
        this.changeDetectorRef.detectChanges();
        this.updateData();
      });
    }
  }

  private generatePageMeta(data: ArticleDto) {
    const title = `Abuduba - ${data.title}`;

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: getMainPictureUrl([data.file], 'small'),
    });

    if (data.seoDescription) {
      this.metaService.updateTag({
        name: 'description',
        content: data.seoDescription,
      });
      this.metaService.updateTag({
        name: 'og:description',
        content: data.seoDescription,
      });
    }

    if (data.seoKeywords) {
      this.metaService.updateTag({
        name: 'keywords',
        content: data.seoKeywords,
      });
    }
  }

  public updateData(): void {
    const params = this.activatedRoute.snapshot.paramMap;
    const index = String(params.get('index'));

    this.articlesApiService.getArticleByIndex({ index }).subscribe({
      next: (routeData) => {
        this.article = routeData;
        this.generatePageMeta(routeData);
        this.changeDetectorRef.detectChanges();
      },
      error: (err) => {
        if (err?.status === 404) {
          this.article = null;
          this.changeDetectorRef.detectChanges();
        } else {
          console.error('Error fetching article data:', err);
        }
      },
    });
  }

  protected readonly getCategoryName = getCategoryName;
}
