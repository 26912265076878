<div id="restaurants_list" class="content-wrap" [class.map-view]="isMapView">
  <app-breadcrumb [items]="getBreadcrumbs()"></app-breadcrumb>

  <div class="header">
    <div class="filters">
      <div class="filters-button" (click)="isFiltersOpen = true">
        <ng-icon name="matFilterAltOutline"></ng-icon>
        Filters
      </div>

      <div class="map-filter">
        <div
          class="map-button"
          *ngIf="!isMapView"
          (click)="toggleMapView(true)">
          <ng-icon name="matMapOutline" *ngIf="!isMapLoading"></ng-icon>
          <div class="loading-animation" *ngIf="isMapLoading"></div>
          Show map
        </div>

        <div
          class="list-button"
          *ngIf="isMapView"
          (click)="toggleMapView(false)">
          <ng-icon name="matListOutline"></ng-icon>
          Show list
        </div>
      </div>

      <div
        class="locate-block"
        [class.loading]="isLocationLoading"
        (click)="updateCurrentPosition()"
        *ngIf="!currentLocation">
        <ng-icon name="matMyLocationOutline"></ng-icon>
        <span>Locate me</span>
      </div>
    </div>

    <div class="header-group">
      <div class="title-block">
        <div class="title">
          <h1>Explore restaurants</h1>
          <div class="loading-animation" *ngIf="isLoading"></div>
        </div>
        <div class="totals">
          <div class="total">{{ totalFilteredItems }} results</div>
        </div>
      </div>

      <div class="actions-block">
        <div
          class="locate-block"
          [class.loading]="isLocationLoading"
          (click)="updateCurrentPosition()"
          *ngIf="!currentLocation">
          <ng-icon name="matMyLocationOutline"></ng-icon>
          <span>Locate me</span>
        </div>

        <div class="sorting-block" *ngIf="!isMapView">
          <app-custom-select
            icon="bootstrapSortDown"
            title="Sort By"
            (valueChange)="updateSortBy($event)"
            value="Rating"
            [items]="[
              {
                label: 'Rating',
                value: 'Rating'
              },
              {
                label: 'Price',
                value: 'Price'
              }
            ]"></app-custom-select>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <div
      id="filters"
      [class.open]="isFiltersOpen"
      (click)="closeFilters($event)">
      <div class="filters-body shadow-block">
        <div class="header">
          <div class="title">Filters</div>
          <div class="actions">
            <div class="close" (click)="isFiltersOpen = false">
              <ng-icon name="matCloseOutline"></ng-icon>
            </div>
          </div>
        </div>

        <div class="filters-list">
          <app-filter-checkbox
            [options]="establishmentTypesFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="establishmentTypesFilter"
            title="Establishment type"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="awardsFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="awardsFilter"
            title="Awards"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="highlightsFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="highlightsFilter"
            title="Highlights"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="cuisinesFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="cuisinesFilter"
            title="Cuisines"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="regionsFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="regionsFilter"
            title="Regions"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="ratingFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="ratingFilter"
            class="rating-filter"
            title="Rating"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="hoursFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="hoursFilter"
            title="Working hours"></app-filter-checkbox>

          <app-filter-checkbox
            [options]="priceLevelFilterOptions"
            (valueChange)="onFiltersChange()"
            [(value)]="priceLevelFilter"
            title="Price"></app-filter-checkbox>
        </div>

        <div class="show-button" (click)="isFiltersOpen = false">
          Show {{ totalFilteredItems }} results
        </div>
      </div>
    </div>

    <div class="restaurants-content">
      <div class="error-message" *ngIf="userLocationError">
        Geolocation error: {{ userLocationError }}
      </div>

      <div id="nearest_restaurants" *ngIf="nearestRestaurants">
        <div class="nearest-restaurants-list">
          <app-restaurant-near-by
            *ngFor="let p of nearestRestaurants"
            [restaurant]="p.restaurant"
            [distance]="p.distance"></app-restaurant-near-by>
        </div>
      </div>

      <div id="restaurants_map_body" *ngIf="isMapView">
        <app-places-map
          [loading]="isMapLoading"
          [zoom]="11"
          (located)="updateCurrentPosition(true)"></app-places-map>
      </div>

      <div id="restaurants_list_body" *ngIf="!isMapView">
        <div class="not-found" *ngIf="restaurants?.length === 0 && !isLoading">
          Restaurants not found
        </div>

        <app-loader *ngIf="isLoading && !restaurants"></app-loader>

        <div class="list" *ngIf="!isLoading">
          <app-entities-preview-list
            [enableAutoHorizontalChange]="true"
            [restaurants]="restaurants"></app-entities-preview-list>
        </div>

        <div
          class="pagination-block"
          *ngIf="restaurants && restaurants.length > 0">
          <app-pagination
            [totalPages]="totalPages"
            [currentPage]="page"
            (pageChanged)="onPageChanged($event)"
            [isLoading]="isLoading"></app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
