import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ArticlePreviewDto,
  ArticlesApiService,
} from '../../core/api/generated/abuduba-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ActivatedRoute } from '@angular/router';
import { articleCategories } from '../articles.types';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { PaginationComponent } from '../../core/pagination/pagination.component';
import { ApiHelper } from '../../core/api/api.helper';
import {
  ArticleDtoCategoryEnum,
  GetArticlesPreviewSortByEnum,
} from '../../core/api/generated/abuduba-api/shared-enums';
import { IBreadcrumb } from '../../core/breadcrumb/breadcrumb.component';

dayjs.extend(utc);

@Component({
  selector: 'app-articles',
  styleUrls: ['article-list-page.component.scss'],
  templateUrl: 'article-list-page.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticleListPageComponent implements OnInit {
  public articles?: ArticlePreviewDto[];

  public totalItems = 0;
  public totalFilteredItems = 0;

  public page = 1;
  public limit = 25;
  public isLoading = false;
  public isHorizontal = true;

  @ViewChild(PaginationComponent)
  public paginationComponent?: PaginationComponent;

  constructor(
    private readonly artilesApiService: ArticlesApiService,
    private route: ActivatedRoute,
    private readonly breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private metaService: Meta,
    private changeDetectorRef: ChangeDetectorRef,
    private apiHelper: ApiHelper,
  ) {
    this.generatePageMeta();
  }

  getBreadcrumbs(): IBreadcrumb[] {
    if (!this.articles) {
      return [];
    }

    return [
      {
        label: 'Home',
        url: '/',
      },
      {
        label: 'Articles',
        url: `/articles`,
      },
    ];
  }

  private generatePageMeta() {
    const title = 'Abuduba - Articles & Travel Guides';
    const description =
      'Explore a variety of travel guides and articles on Abuduba. From insider tips on UAE attractions to cultural insights and adventure ideas, discover everything you need to plan your next trip across Dubai, Abu Dhabi, and beyond.';
    const keywords =
      'Abuduba articles, UAE travel guides, Dubai travel tips, Abu Dhabi travel insights, explore UAE, travel planning UAE, cultural guides UAE, adventure ideas UAE, Abuduba travel articles, travel resources UAE, popular travel guides, Abuduba service';

    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'og:title',
      content: title,
    });

    this.metaService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });

    this.metaService.updateTag({
      name: 'og:image',
      content: `${environment.dashboardUrl}/assets/logo.png`,
    });

    this.metaService.updateTag({
      name: 'keywords',
      content: keywords,
    });
  }

  public categoriesFilter = new Set<ArticleDtoCategoryEnum>();

  private destroy$ = new Subject<void>();

  public get categories() {
    return Array.from(
      Object.entries(articleCategories).map(([i, c]) => ({
        ...c,
        filtered: this.categoriesFilter.has(i as ArticleDtoCategoryEnum),
        id: i,
      })),
    );
  }

  ngOnInit() {
    const initialValue =
      this.breakpointObserver.isMatched('(max-width: 820px)');
    this.isHorizontal = !initialValue;

    this.parseQueryParams();
    this.updateArticles();

    this.breakpointObserver
      .observe('(max-width: 820px)')
      .subscribe((result) => {
        this.isHorizontal = !result.matches;
        this.changeDetectorRef.detectChanges();
      });
  }

  public parseQueryParams() {
    const params = this.route.snapshot.queryParamMap;
    const categories = params.get('categories')?.split(',') || [];

    if (categories.length > 0) {
      this.categoriesFilter = <any>categories;
    }

    const page = parseInt(String(params.get('page')));
    if (page) {
      this.page = page;
    }

    this.changeDetectorRef.markForCheck();
  }

  public filterByCategory(cat: ArticleDtoCategoryEnum, isFiltered: boolean) {
    if (isFiltered) {
      this.categoriesFilter.add(cat);
    } else {
      this.categoriesFilter.delete(cat);
    }

    this.changeDetectorRef.detectChanges();
    this.updateArticles();
  }

  public articleTrackBy(index: number, article: ArticlePreviewDto) {
    return article.index;
  }

  public onPageChanged(page: number) {
    this.page = page;

    this.updateArticles();
  }

  public onFiltersChange() {
    this.page = 1;
    this.paginationComponent?.setPage(this.page, false);

    setTimeout(() => {
      this.updateArticles();
    }, 0);
  }

  public get totalPages() {
    return Math.ceil(this.totalFilteredItems / this.limit) || 1;
  }

  public updateArticles() {
    this.isLoading = true;
    this.changeDetectorRef.detectChanges();

    this.destroy$.next();

    this.artilesApiService
      .searchArticlesPreview({
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
        categories: Array.from(this.categoriesFilter.values()),
        sortBy: GetArticlesPreviewSortByEnum.CreatedAt,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.articles = data.items;
          this.totalItems = data.totalItems;
          this.totalFilteredItems = data.totalFilteredItems;
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
        complete: () => {
          this.changeDetectorRef.detectChanges();
        },
      });
  }

  protected readonly articleCategories = articleCategories;
}
